import type { MicroFrontendMetadata } from "@newsenec/metadata-gen"
import { v4 as uuid } from "uuid"
import { fetchAllMetadata, fetchImportMap, getImportMapOverrides } from "@newsenec-lib/micro-frontend-utils"

function createNoMetadataHash() {
    return "no-metadata-" + uuid()
}

function modifyImports(importMap: System.ImportMap, metadata: MicroFrontendMetadata[]) {
    for (const importName in importMap.imports) {
        const importMetadata = metadata.find((d) => d.name === importName)
        const hash = importMetadata?.commitHash ?? createNoMetadataHash()
        importMap.imports[importName] += `?hash=${hash}`
    }
}

/**
 * Removes the import map overrides set by the user / developer.
 */
function removeOverrides(importMap: System.ImportMap) {
    if (!importMap.imports) return
    const overrides = getImportMapOverrides()
    for (const importName in overrides.imports) {
        delete importMap.imports[importName]
    }
}

/**
 * Modifies the URLs of the import map for cache busting (see e.g, https://www.keycdn.com/support/what-is-cache-busting).
 *
 * ## Why?
 *
 * The browser typically loads the micro frontends from its cache. In case of a new deployment this leads to errors
 * that the user cannot resolve with a simple reload of the page. The cache busting solves this issues by forcing the
 * browser to download the micro frontend if new version was deployment and load it from cache otherwise.
 *
 * ## How?
 *
 * The URL is extended by the search string `?hash=<COMMIT_HASH>`, where the commit hash is take from the metadata of
 * the respective micro frontend. If no metadata could be found a prefixed random hash is picked instead to force cache
 * busting.
 *
 * The URLs to modify are taken from the deployed import map. The single-spa DEV tools typically apply manual overrides
 * set by the user before running this function. To not destroy this the manual override functionality, imports with a
 * manual overrides are skipped from modification.
 */
export async function modifyImportUrls(): Promise<System.ImportMap> {
    const importMap = await fetchImportMap()
    const metadata = await fetchAllMetadata()
    modifyImports(importMap, metadata)
    removeOverrides(importMap)
    return importMap
}
